
import { defineComponent, ref } from "vue";
import { config } from "@/core/helpers/config";

interface Filter {
  status: string;
  author: boolean;
  customer: boolean;
  notifications: boolean;
  ReportDate: Date;
}

export default defineComponent({
  name: "dropdown-1",
  components: {},
  setup() {
    const Filterdata = ref<Filter>({
      status: "1",
      author: true,
      customer: true,
      notifications: true,
      ReportDate: new Date(),
    });

    function SetFilterData(ReportDate) {
      sessionStorage.setItem("DocExchangeDate", ReportDate.getFullYear());
      // console.log(ReportDate);
    }

    return {
      Filterdata,
      SetFilterData,
      config,
    };
  },
});
